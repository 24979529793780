<template>
  <v-row class="mx-0">
    <v-col cols="12" md="4">
      <v-row style="height: 100%">
        <v-col cols="12">
          <slot name="text-5" :visible="true">
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image@2"></v-skeleton-loader>
          </slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="8">
      <v-row style="height: 100%;">
        <v-col cols="12">
          <slot name="images-0" :visible="true"></slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4">
      <v-row style="height: 100%">
        <v-col cols="12">
          <slot
            name="text-4"
            :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
          >
            <v-skeleton-loader class="my-6" type="heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </slot>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="8">
      <v-row style="height: 100%;">
        <v-col cols="12">
          <v-lazy
            v-model="visiblePrimary"
            style="height: 100%"
            :options="{
              threshold: 0.5
            }"
            transition="fade-transition"
          >
            <v-row style="height: 100%;">
              <v-col cols="12" md="6" class="pt-0">
                <slot
                  name="quotes-8"
                  :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
                >
                  <v-skeleton-loader
                    class="my-6"
                    type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader type="image"></v-skeleton-loader>
                </slot>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <slot
                  name="text-3"
                  :visible="$vuetify.breakpoint.smAndDown ? scrolled : true"
                >
                  <v-skeleton-loader
                    class="my-6"
                    type="heading"
                  ></v-skeleton-loader>
                  <v-skeleton-loader type="image"></v-skeleton-loader>
                </slot>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <slot
                  name="journal-7"
                  :visible="visiblePrimary && scrolled"
                ></slot>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <slot
                  name="text-2"
                  :visible="visiblePrimary && scrolled"
                ></slot>
              </v-col>
            </v-row>
          </v-lazy>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-lazy
        v-model="visibleSecondary"
        style="height: 100%"
        :options="{
          threshold: 0.5
        }"
        transition="fade-transition"
      >
        <v-row style="height: 100%">
          <v-col cols="12" md="6">
            <slot name="text-1" :visible="visibleSecondary && scrolled"></slot>
          </v-col>
          <v-col cols="12" md="6">
            <slot name="text-6" :visible="visibleSecondary && scrolled"></slot>
          </v-col>
        </v-row>
      </v-lazy>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditorTemplate3",
  data: () => ({
    visiblePrimary: false,
    visibleSecondary: false,
    scrolled: false
  }),
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = true;
    }
  }
};
</script>
